import { ToolBar } from "../components/Toolbar";
import FitScreenIcon from "@mui/icons-material/FitScreen";
import ArticleIcon from "@mui/icons-material/Article";
import {
  Box,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import PageHeader from "../components/PageHeader";
import PhotoDialog from "../components/PhotoDialog";
import ArtifactsSelectionBar from "../components/ArtifactsSelectionBar";
import { useSearchParams } from "react-router-dom";
import { useTheme } from "@emotion/react";
import axios from "axios";
import { path } from "../consts";
import Loader from "../components/Loader";
import axiosRetry from 'axios-retry';
import Footer from "../components/Footer";



function PostersKG(props) {
  const [isPhotoOpen, setPhotoOpen] = useState(false);
  const [image, setImage] = useState(null);
  const [artifactsList, setArtifactsList] = useState(null);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedEditions, setSelectedEditions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [artifactsTypes, setArtifactsTypes] = useState(null);
  const [competitionEditionList, setCompetitionEditionList] = useState(null);
  const [searchPhrase, setSearchPhrase] = useState(null);

  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("bmd"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div className="App">
      {isLoading ? (
        <Loader />
      ) : (
        <div >
          <Box sx={{ position: "sticky", top: 0, zIndex: 99999 }}>
            <ToolBar></ToolBar>
          </Box>
          <PageHeader />
          <Typography
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            variant={isMobile ? "h4" : "h2"}
            sx={{ mt: {bmd: 10, xs: 7} }}
          >
            Postery 50-lecia
          </Typography> 
          <ImageList
              sx={{ width: "95%", mt: 3 }}
              cols={isLargeScreen ? 3 : isSmallScreen ? 1 : 2}
            >
                    <ImageListItem>
                      <img
                        src='posters/images/historia_poster.png'
                        srcSet='posters/images/historia_poster.png'
                        alt='O Olimpiadzie Geograficznej'
                        loading="lazy"
                      />
                      <ImageListItemBar
                        title='O Olimpiadzie Geograficznej'
                        actionIcon={
                          <IconButton
                            sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                            onClick={() => {
                              window.open(`${window.location.origin}/posters/posters/historia_poster.pdf`)
                            }}
                          >
                            <ArticleIcon />
                          </IconButton>
                        }
                                />
                    </ImageListItem>
                    <ImageListItem>
                      <img
                        src='posters/images/igeo_poster.png'
                        srcSet='posters/images/igeo_poster.png'
                        alt='Międzynarodowe Zawody Geograficzne w Polsce'
                        loading="lazy"
                      />
                      <ImageListItemBar
                        title='Międzynarodowe Zawody Geograficzne w Polsce'
                        actionIcon={
                          <IconButton
                            sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                            onClick={() => {
                              window.open(`${window.location.origin}/posters/posters/igeo_poster.pdf`)
                            }}
                          >
                            <ArticleIcon />
                          </IconButton>
                        }
                                />
                    </ImageListItem>
                    <ImageListItem>
                      <img
                        src='posters/images/ludzie_poster.png'
                        srcSet='posters/images/ludzie_poster.png'
                        alt='Ludzie Olimpiady'
                        loading="lazy"
                      />
                      <ImageListItemBar
                        title='Ludzie Olimpiady'
                        actionIcon={
                          <IconButton
                            sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                            onClick={() => {
                              window.open(`${window.location.origin}/posters/posters/ludzie_poster.pdf`)
                            }}
                          >
                            <ArticleIcon />
                          </IconButton>
                        }
                                />
                    </ImageListItem>
                    <ImageListItem>
                      <img
                        src='posters/images/mapy_poster.png'
                        srcSet='posters/images/mapy_poster.png'
                        alt='Historia Olimpiady'
                        loading="lazy"
                      />
                      <ImageListItemBar
                        title='Miasta i Uczestnicy Zawodów Finałowych'
                        actionIcon={
                          <IconButton
                            sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                            onClick={() => {
                              window.open(`${window.location.origin}/posters/posters/mapy_poster.pdf`)
                            }}
                          >
                            <ArticleIcon />
                          </IconButton>
                        }
                                />
                    </ImageListItem>
                    <ImageListItem>
                      <img
                        src='posters/images/miedzynarodowe_poster.png'
                        srcSet='posters/images/miedzynarodowe_poster.png'
                        alt='Starty Polaków w Międzynarodowych Zawodach Geograficznych'
                        loading="lazy"
                      />
                      <ImageListItemBar
                        title='Starty Polaków w Międzynarodowych Zawodach Geograficznych'
                        actionIcon={
                          <IconButton
                            sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                            onClick={() => {
                              window.open(`${window.location.origin}/posters/posters/miedzynarodowe_poster.pdf`)
                            }}
                          >
                            <ArticleIcon />
                          </IconButton>
                        }
                                />
                    </ImageListItem>
                    <ImageListItem>
                      <img
                        src='posters/images/os_czasu_poster.png'
                        srcSet='posters/images/os_czasu_poster.png'
                        alt='Historia Olimpiady Geograficznej - Oś Czasu'
                        loading="lazy"
                      />
                      <ImageListItemBar
                        title='Historia Olimpiady Geograficznej - Oś Czasu'
                        actionIcon={
                          <IconButton
                            sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                            onClick={() => {
                              window.open(`${window.location.origin}/posters/posters/os_czasu_poster.pdf`)
                            }}
                          >
                            <ArticleIcon />
                          </IconButton>
                        }
                                />
                    </ImageListItem>
                    <ImageListItem>
                      <img
                        src='posters/images/zadania_poster.png'
                        srcSet='posters/images/zadania_poster.png'
                        alt='Struktura Zawodów i Zadania Olimpijskie'
                        loading="lazy"
                      />
                      <ImageListItemBar
                        title='Struktura Zawodów i Zadania Olimpijskie'
                        actionIcon={
                          <IconButton
                            sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                            onClick={() => {
                              window.open(`${window.location.origin}/posters/posters/zadania_poster.pdf`)
                            }}
                          >
                            <ArticleIcon />
                          </IconButton>
                        }
                                />
                    </ImageListItem>
                    <ImageListItem>
                      <img
                        src='posters/images/wycieczki_poster.png'
                        srcSet='posters/images/wycieczki_poster.png'
                        alt='Podróże po Polsce z Olimpiadą Geograficzną'
                        loading="lazy"
                      />
                      <ImageListItemBar
                        title='Podróże po Polsce z Olimpiadą Geograficzną'
                        actionIcon={
                          <IconButton
                            sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                            onClick={() => {
                              window.open(`${window.location.origin}/posters/posters/wycieczki_poster.pdf`)
                            }}
                          >
                            <ArticleIcon />
                          </IconButton>
                        }
                                />
                    </ImageListItem>
            </ImageList>
          <Footer/>
        </div>
      )}
    </div>
  );
}

export default PostersKG;
